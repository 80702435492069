import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
import Blockquote from 'components/Blockquote';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Logo wrap=flex",
        "componentName": "Logo",
        "wrap": "flex"
      }}>{`<Logo />
`}</code></pre>
    <Blockquote title="Extension resolving" mdxType="Blockquote">
      <p>{`  The correct functioning of this component relies on extension resolving.
In our documentation website, we don't work with extension resolving (as we
dinamically load in every theme) and as such the preview of this component
will always display Whitelabel's logos.`}</p>
    </Blockquote>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="Logo" mdxType="Props" />
    <h2 {...{
      "id": "name",
      "style": {
        "position": "relative"
      }
    }}>{`Name`}</h2>
    <p><inlineCode parentName="p">{`default: brand-main`}</inlineCode></p>
    <p>{`This is the name of the logo, as can be found on `}<a parentName="p" {...{
        "href": "/foundations/logos"
      }}>{`the logos page`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Logo name="brand-main" />
<Logo name="brand-square-main" />
<Logo name="brand-plus-main" />
`}</code></pre>
    <h2 {...{
      "id": "extension",
      "style": {
        "position": "relative"
      }
    }}>{`Extension`}</h2>
    <p><inlineCode parentName="p">{`default: svg`}</inlineCode></p>
    <p>{`Use logo as svg or png, try to use svg as much as possible.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Logo name="brand-main" extension="svg" />
<Logo name="brand-main" extension="png" />
`}</code></pre>
    <h2 {...{
      "id": "height",
      "style": {
        "position": "relative"
      }
    }}>{`Height`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Change the logo's height.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Logo name="brand-main" height={5} />
<Logo name="brand-main" height="full" />
<Logo name="brand-main" height="auto" />
<Logo name="brand-main" height="300px" />
`}</code></pre>
    <h2 {...{
      "id": "maxheight",
      "style": {
        "position": "relative"
      }
    }}>{`MaxHeight`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Change the logo's max-height.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Logo name="brand-main" maxHeight={5} />
<Logo name="brand-main" maxHeight="full" />
<Logo name="brand-main" maxHeight="auto" />
<Logo name="brand-main" maxHeight="300px" />
`}</code></pre>
    <h2 {...{
      "id": "maxwidth",
      "style": {
        "position": "relative"
      }
    }}>{`MaxWidth`}</h2>
    <p><inlineCode parentName="p">{`default: full`}</inlineCode></p>
    <p>{`Change the logo's max-width.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Logo name="brand-main" maxWidth={5} />
<Logo name="brand-main" maxWidth="full" />
<Logo name="brand-main" maxWidth="auto" />
<Logo name="brand-main" maxWidth="300px" />
`}</code></pre>
    <h2 {...{
      "id": "width",
      "style": {
        "position": "relative"
      }
    }}>{`Width`}</h2>
    <p><inlineCode parentName="p">{`default: auto`}</inlineCode></p>
    <p>{`Change the logo's width.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Logo name="brand-main" width={5} />
<Logo name="brand-main" width="full" />
<Logo name="brand-main" width="auto" />
<Logo name="brand-main" width="300px" />
`}</code></pre>
    <h2 {...{
      "id": "verticalalign",
      "style": {
        "position": "relative"
      }
    }}>{`VerticalAlign`}</h2>
    <p><inlineCode parentName="p">{`default: middle`}</inlineCode></p>
    <p>{`Vertical align the logo against text.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Text fontFamily="system">
Lorem ipsum dolor sit amet, consectetur adipiscing elit. <Logo name="brand-main" verticalAlign="baseline" height={5} />
Morbi eget tortor volutpat orci fermentum gravida. <Logo name="brand-main" verticalAlign="bottom" height={5} />
Morbi sodales lacus id nibh posuere, in dapibus erat ornare. Maecenas scelerisque facilisis luctus. <Logo name="brand-main" verticalAlign="inherit" height={5} />
Nam at purus eu orci accumsan scelerisque. <Logo name="brand-main" verticalAlign="initial" height={5} />
Morbi molestie aliquet consectetur. <Logo name="brand-main" verticalAlign="middle" height={5} />
Phasellus ac felis rhoncus, tristique enim non, congue ex. <Logo name="brand-main" verticalAlign="sub" height={5} />
Curabitur non enim nisl. <Logo name="brand-main" verticalAlign="super" height={5} />
Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. <Logo name="brand-main" verticalAlign="text-bottom" height={5} />
Lorem ipsum dolor sit amet, consectetur adipiscing elit. <Logo name="brand-main" verticalAlign="text-top" height={5} />
Morbi eget tortor volutpat orci fermentum gravida. <Logo name="brand-main" verticalAlign="top" height={5} />
Morbi sodales lacus id nibh posuere, in dapibus erat ornare. Maecenas scelerisque facilisis luctus. <Logo name="brand-main" verticalAlign="unset" height={5} />
</Text>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      